import { cosConfig } from '@/services/api';
import { message } from 'antd';
import COS from 'cos-js-sdk-v5';
import { useCallback } from 'react';
import { isBrowser } from 'umi';

export default function useCosModel() {
  let cos: COS;

  if (isBrowser()) {
    cos = new COS({
      getAuthorization: function (options, callback) {
        cosConfig().then((res) => {
          callback({
            TmpSecretId: res.data.credentials.tmpSecretId,
            TmpSecretKey: res.data.credentials.tmpSecretKey,
            SecurityToken: res.data.credentials.sessionToken,
            // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
            StartTime: res.data.startTime, // 时间戳，单位秒，如：1580000000
            ExpiredTime: res.data.expiredTime, // 时间戳，单位秒，如：1580000000
          });
        });
      },
    });
  }

  const upload = useCallback((e: any) => {
    cos
      .putObject({
        Bucket: 'beibuwan-portal-1253836587' /* 必须 */,
        Region: 'ap-guangzhou' /* 存储桶所在地域，必须字段 */,
        Key: `${e.file.uid}-${e.file.name}` /* 必须 */,
        StorageClass: 'STANDARD',
        Body: e.file, // 上传文件对象
        ContentLength: e.file.size,
        onProgress: function (progressData) {
          e.onProgress?.(progressData);
        },
      })
      .then((res) => {
        e.onSuccess?.(`https://${res.Location}`);
      })
      .catch(() => {
        message.error('上传失败，请重试！');
      });
  }, []);

  return {
    upload,
  };
}
