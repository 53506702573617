import { useState, useCallback, useEffect } from 'react';
import { request, history } from 'umi';
import { message } from 'antd';
import { encrypt } from '@/utils/crypto';
import { currentUser } from '@/services/api';

export default function useAuthModel() {

  const [user, setUser] = useState<API.CurrentUser>()

  useEffect(() => {
    currentUser({
      skipErrorHandler: true,
    }).then((res) => {
      setUser(res.data)
    }).catch(() => { })
  }, [])

  const signin = useCallback((account, password, redirect) => {
    if (account && password) {
      request('/api/user/login', { method: 'POST', data: { username: account, password } })
        .then(async (res) => {
          localStorage.setItem('jwt', res.t);
          localStorage.setItem('accessToken', res.accessToken);
          localStorage.setItem('expiresIn', res.expiresIn);
          localStorage.setItem('refreshToken', res.refreshToken);
          const { data: user } = await currentUser();
          setUser(user)
          if (redirect) {
            window.location.replace(`${redirect}?jwt=${res.t}&accessToken=${res.accessToken}`)
          } else {
            history.push('/');
          }
        }).catch(() => { })
    } else {
      message.error('请填写账号、密码!');
    }
  }, []);

  const signout = useCallback(() => {
    localStorage.clear();
    setUser(undefined)
    history.push('/');
  }, []);


  const register = useCallback((account, password) => {
    const registerToTaobao = () => {
      const data = {
        userName: account,
        passWord: encrypt(password),
      };
      request('/taobao/user/register', { method: 'POST', data }).then((res) => {
        localStorage.setItem('accessToken', res.accessToken);
        localStorage.setItem('expiresIn', res.expiresIn);
        localStorage.setItem('refreshToken', res.refreshToken);
      });
    };

    if (account && password) {
      request('/api/user/register', { method: 'POST', data: { username: account, password } })
        .then(async (res) => {
          localStorage.setItem('jwt', res.t);
          const { data: user } = await currentUser();
          setUser(user)
          registerToTaobao();
        }).catch(() => { })
    } else {
      message.error('请填写账号、密码!');
    }
  }, []);

  return {
    user,
    signin,
    signout,
    register,
  };
}
