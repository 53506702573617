import { history, isBrowser, RequestConfig } from 'umi';
import { RequestOptionsInit } from 'umi-request';

const loginPath = '/login';
const registerPath = '/register';
const resetPath = '/reset/password';
const welcomePath = '/';

// 登录相应拦截器
const responseInterceptor = async (response: Response) => {
  if (isBrowser()) {
    if (response.status !== 200) {
      if (response.status === 401) {
        const path = history.location.pathname
        if (path !== loginPath && path !== registerPath && path !== welcomePath && path !== resetPath) {
          localStorage.clear();
          history.push('/');
        }
      }
    }
  }
  return response;
};

const requestInterceptor = (url: string, options: RequestOptionsInit) => {
  if (isBrowser()) {
    if (url.indexOf('/pc-shop') !== -1) {
      const accessToken = localStorage.getItem('accessToken');
      const authHeader = { Authorization: `${accessToken}` };
      return {
        url,
        options: { ...options, interceptors: true, headers: authHeader },
      };
    } else {
      const jwt = localStorage.getItem('jwt');
      const authHeader = { Authorization: `Bearer ${jwt}` };
      return {
        url,
        options: { ...options, interceptors: true, headers: authHeader },
      };
    }
  } else {
    return {
      url,
      options,
    };
  }
};

export const request: RequestConfig = {
  // timeout: 1000,
  // errorConfig: {},
  // middlewares: [],
  requestInterceptors: [requestInterceptor],
  responseInterceptors: [responseInterceptor],
};
