import React from 'react';
import { Spin } from 'antd';

const Loading: React.FC = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Spin></Spin>
    </div>
  );
};

export default Loading;
