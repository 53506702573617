// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from 'C:/Users/Administrator/Desktop/beibuwan-portal/frontend/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/Loading';

export function getRoutes() {
  const routes = [
  {
    "path": "/ssr",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ssr' */'@/pages/ssr'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "path": "/login",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__login' */'@/pages/login'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "path": "/register",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__login__register' */'@/pages/login/register'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "path": "/reset/password",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__login__reset' */'@/pages/login/reset'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "path": "/admin/dashboard",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__dashboard' */'@/layouts/dashboard'), loading: LoadingComponent}),
    "routes": [
      {
        "exact": true,
        "path": "/admin/dashboard",
        "redirect": "/admin/dashboard/user"
      },
      {
        "path": "/admin/dashboard/home",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin' */'@/pages/admin'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "icon": "idCard",
        "name": "平台用户管理",
        "path": "/admin/dashboard/user",
        "key": "user",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__user' */'@/pages/admin/user'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "name": "平台审核管理",
        "icon": "dashboard",
        "path": "/admin/dashboard/audit",
        "key": "audit",
        "routes": [
          {
            "name": "科技成果审核",
            "path": "/admin/dashboard/audit/achievement",
            "key": "auditAchievement",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__achievement' */'@/pages/admin/achievement'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "科技需求审核",
            "path": "/admin/dashboard/audit/requirement",
            "key": "auditRequirement",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__requirement' */'@/pages/admin/requirement'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "服务匹配撮合",
            "path": "/admin/dashboard/audit/mate",
            "key": "auditMate",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__mate' */'@/pages/admin/mate'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "icon": "file",
        "name": "服务合同管理",
        "path": "/admin/dashboard/contract",
        "key": "contract",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__contract' */'@/pages/contract'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/admin/dashboard/activity",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__activity' */'@/pages/admin/activity'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/admin/dashboard/policy",
        "icon": "smile",
        "name": "政策资讯管理",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__policy' */'@/pages/admin/policy'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/admin/dashboard/config/icp",
        "icon": "heart",
        "name": "网站配置",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__icp' */'@/pages/admin/icp'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "path": "/user/dashboard",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__dashboard' */'@/layouts/dashboard'), loading: LoadingComponent}),
    "routes": [
      {
        "exact": true,
        "path": "/user/dashboard",
        "redirect": "/user/dashboard/auth"
      },
      {
        "path": "/user/dashboard/auth",
        "name": "用户信息管理",
        "icon": "idCard",
        "key": "userAuth",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__userCenter__auth' */'@/pages/userCenter/auth'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/user/dashboard/info",
        "icon": "user",
        "name": "资质认证管理",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__userCenter__info' */'@/pages/userCenter/info'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/user/dashboard/requirement",
        "icon": "heart",
        "name": "技术需求管理",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__userCenter__requirement' */'@/pages/userCenter/requirement'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/user/dashboard/achievement",
        "icon": "dashboard",
        "name": "科技成果管理",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__userCenter__achievement' */'@/pages/userCenter/achievement'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/user/dashboard/taobao",
        "icon": "file",
        "name": "服务交易管理",
        "routes": [
          {
            "name": "上链订单管理",
            "path": "/user/dashboard/taobao/blockchainOrder",
            "key": "blockchainOrder",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__userCenter__blockchainOrder' */'@/pages/userCenter/blockchainOrder'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "交易订单管理",
            "path": "/user/dashboard/taobao/order",
            "key": "taobaoOrder",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__userCenter__order' */'@/pages/userCenter/order'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "服务合同管理",
            "path": "/user/dashboard/taobao/contract",
            "key": "taobaoContract",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__contract' */'@/pages/contract'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/user/dashboard/invitation",
        "icon": "smile",
        "name": "邀约交流管理",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__userCenter__invitation' */'@/pages/userCenter/invitation'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__portal' */'@/layouts/portal'), loading: LoadingComponent}),
    "routes": [
      {
        "exact": true,
        "path": "/",
        "redirect": "/home"
      },
      {
        "path": "/home",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home' */'@/pages/home'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/resourcePool",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__resourcePool' */'@/pages/resourcePool'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/resourcePool/patent",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__resourcePool__patent' */'@/pages/resourcePool/patent'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/resourcePool/collection/:type",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__resourcePool__collection' */'@/pages/resourcePool/collection'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/resourcePool/collection/:type/detail/:id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__resourcePool__collection__detail' */'@/pages/resourcePool/collection/detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/searchStd",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__searchStd' */'@/pages/searchStd'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/bigdata",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__bigdata' */'@/pages/bigdata'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/blockchain",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__blockchain' */'@/pages/blockchain'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/patentMap",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__patentMap' */'@/pages/patentMap'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/techMap",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__techMap' */'@/pages/techMap'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/activity",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__activity' */'@/pages/activity'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/activity/add",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__activity__add' */'@/pages/activity/add'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/achievement",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__achievement' */'@/pages/achievement'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/achievement/detail/:id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__achievement__detail' */'@/pages/achievement/detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/achievement/add",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__achievement__add' */'@/pages/achievement/add'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/policy",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__policy' */'@/pages/policy'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/policy/detail/:id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__policy__detail' */'@/pages/policy/detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/requirement",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__requirement' */'@/pages/requirement'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/requirement/add",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__requirement__add' */'@/pages/requirement/add'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/requirement/detail/:id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__requirement__detail' */'@/pages/requirement/detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/requirement/delivery/:id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__requirement__delivery' */'@/pages/requirement/delivery'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'@/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'@/pages/404'), loading: LoadingComponent}),
    "exact": true
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
