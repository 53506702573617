import { request } from 'umi';

/** 获取当前的用户 GET /api/currentUser */
export async function currentUser(options?: { [key: string]: any }) {
  return request<{
    data: API.CurrentUser;
  }>('/api/user/current', {
    ...(options || {}),
  });
}

/** 获取当前的COS密钥 */
export async function cosConfig(options?: { [key: string]: any }) {
  return request<{
    data: API.CosConfig;
  }>('/api/config/cos/open', {
    method: 'GET',
    ...(options || {}),
  });
}
